import React, { useCallback, useRef, useState } from 'react';

import { QBox, QCenter, QStack } from '../../QLayouts';
import { UploadInProgress } from './UploadInProgress';
import { FileInputBody } from './FileInputBody';
import { QText } from '../../QAtoms';
import { FileListItem, filesizeToUnitSize, QFileInputValue } from './types';
import { FileItemList } from './FileItemList';

export type QFileInputProps = {
  onFileUpload: (files: FileList | null) => void;
  accept?: string;
  description?: string;
  isUploading?: boolean;
  allowMultiple?: boolean;
  errorMessage?: string;
};

export const QFileInput: React.FC<QFileInputProps> = ({
  onFileUpload,
  accept,
  description,
  isUploading,
  allowMultiple,
  errorMessage,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleBrowse = useCallback(() => {
    fileInputRef.current?.click();
  }, [fileInputRef]);

  const handleOnDrag = useCallback(
    (e) => {
      e.preventDefault();
      onFileUpload(e.dataTransfer.files);
    },
    [onFileUpload],
  );

  return (
    <QBox
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={(e) => handleOnDrag(e)}
    >
      <input
        style={{ display: 'none' }}
        type="file"
        accept={accept}
        onChange={(e) => onFileUpload(e.target.files)}
        ref={fileInputRef}
        multiple={!!allowMultiple}
      />
      <QCenter
        width="full"
        minHeight="150px"
        backgroundColor="gray.50"
        borderRadius="4px"
        border={!!errorMessage ? '1px solid red.200' : undefined}
        background={!!errorMessage ? 'red.50' : undefined}
        padding="24px 16px"
      >
        <QStack direction="column" alignItems="center">
          {isUploading ? (
            <UploadInProgress />
          ) : (
            <>
              <FileInputBody
                handleBrowse={handleBrowse}
                description={description}
              />
              {!!errorMessage && (
                <QText fontSize="sm" color="red.900">
                  {errorMessage}
                </QText>
              )}
            </>
          )}
        </QStack>
      </QCenter>
    </QBox>
  );
};

export type QFileInputUpload = { isLoading: boolean };

export type QSingleFileInputProps = {
  maxFileSize?: number;
  value?: QFileInputValue;
  onUploadFile: (file: File) => Promise<QFileInputValue>;
  onDownloadFile?: (file: QFileInputValue) => void;
  onChange?: (value: QFileInputValue | undefined) => void;
} & Pick<QFileInputProps, 'accept' | 'description'>;
export const QSingleFileInput: React.FC<QSingleFileInputProps> = ({
  maxFileSize,
  value,
  accept,
  description,
  onChange,
  onUploadFile,
  onDownloadFile,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [isLoading, setIsLoading] = useState(false);

  const onFileUpload = useCallback(
    async (files: FileList | null) => {
      if (!files || !files[0]) {
        return;
      }

      if (maxFileSize && files[0].size > maxFileSize) {
        setErrorMessage(
          `File size exceeds ${filesizeToUnitSize(
            maxFileSize,
          )}. Please try again.`,
        );
        return;
      }

      // upload file
      setErrorMessage(undefined);

      setIsLoading(true);
      try {
        const newFile = await onUploadFile(files[0]);
        onChange?.(newFile);
      } catch (e) {
        setErrorMessage('Upload failed. Please try again.');
      }
      setIsLoading(false);
    },
    [onChange, onUploadFile],
  );

  if (value) {
    return (
      <FileItemList
        files={[value]}
        onDelete={() => onChange?.(undefined)}
        onDownload={onDownloadFile}
      />
    );
  }

  return (
    <QFileInput
      onFileUpload={onFileUpload}
      description={description}
      accept={accept}
      errorMessage={errorMessage}
      isUploading={isLoading}
    />
  );
};

export type QMultiFileInputProps = {
  maxFiles: number;
  maxFileSize?: number;
  maxCombinedSize?: number;
  value: QFileInputValue[];
  onUploadFile: (file: File) => Promise<QFileInputValue>;
  onDownloadFile?: (file: QFileInputValue) => void;
  onChange: (value: QFileInputValue[]) => void;
} & Pick<QFileInputProps, 'accept' | 'description'>;
export const QMultiFileInput: React.FC<QMultiFileInputProps> = ({
  accept,
  description,
  value: initialValue,
  onChange,
  maxFiles,
  maxCombinedSize,
  maxFileSize,
  onDownloadFile,
  onUploadFile,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [value, setValue] = useState<FileListItem[]>(initialValue);
  const [isLoading, setIsLoading] = useState(false);

  const onFileUpload = useCallback(
    async (files: FileList | null) => {
      if (!files) {
        return;
      }

      const fileArray = Array.from(files);

      if (maxFiles && fileArray.length + value.length > maxFiles) {
        setErrorMessage('Maximum number of files exceeded.');
        return;
      }

      const combinedFileSize =
        fileArray.reduce((total, file) => total + file.size, 0) +
        value.reduce((total, file) => total + file.filesize, 0);
      if (maxCombinedSize && combinedFileSize > maxCombinedSize) {
        setErrorMessage(
          `Combined file size exceeds ${filesizeToUnitSize(
            maxCombinedSize,
          )}. Please try again.`,
        );
        return;
      }

      // filter files over the maxFileSize
      const filesWithErrors = fileArray.filter(
        (file) => maxFileSize && file.size > maxFileSize,
      );
      const filesToUpload = fileArray.filter((file) =>
        maxFileSize ? file.size < maxFileSize : true,
      );

      // do file upload
      setIsLoading(true);
      const result = await Promise.allSettled(filesToUpload.map(onUploadFile));

      const newValue = [
        ...value,
        ...filesWithErrors.map(
          (file): FileListItem => ({
            filename: file.name,
            filesize: file.size,
            id: file.name,
            errorMessage: `File size exceeds ${filesizeToUnitSize(
              maxFileSize ?? 0,
            )}. Please try again.`,
          }),
        ),
        ...result.map((r, i): FileListItem => {
          if (r.status === 'rejected') {
            const file = filesToUpload[i];
            return {
              filename: file.name,
              filesize: file.size,
              id: file.name,
              errorMessage: 'Upload failed. Please try again.',
            };
          }

          return r.value;
        }),
      ];
      setValue(newValue);
      onChange?.(newValue.filter((s) => !s.errorMessage));
      setIsLoading(false);
      setErrorMessage(undefined);
    },
    [value, setValue],
  );

  const onItemdelete = useCallback(
    (item: QFileInputValue) => {
      const findIndex = value.findIndex((v) => v.id === item.id);
      if (findIndex === -1) {
        return;
      }

      const valueToMutate = [...value];
      valueToMutate.splice(findIndex, 1);
      setValue(valueToMutate);
      onChange?.(valueToMutate);
    },
    [value, setValue],
  );

  return (
    <QStack>
      <QFileInput
        onFileUpload={onFileUpload}
        description={description}
        accept={accept}
        errorMessage={errorMessage}
        isUploading={isLoading}
        allowMultiple
      />
      {value && value.length > 0 && (
        <FileItemList
          files={value}
          onDelete={onItemdelete}
          onDownload={onDownloadFile}
        />
      )}
    </QStack>
  );
};
