import React from 'react';
import {
  FormControl,
  FormControlProps,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { QStack } from '../../QLayouts';
import { usePartitionedChildren } from '../../utils';

export interface QFormControlProps extends FormControlProps {
  label?: string;
  error?: string;
  helper?: string | React.ReactElement;
}

export const QFormControl: React.FC<QFormControlProps> = (props) => {
  const { label, error, helper, ...rest } = props;

  const { QLabelAction: labelAction, unmatched: children } =
    usePartitionedChildren(props.children, 'QLabelAction');

  return (
    <FormControl {...rest}>
      <QStack direction="row" alignItems="baseline" spacing={0}>
        {!!label && (
          <FormLabel
            fontWeight="semibold"
            fontSize="sm"
            marginBottom={helper ? 0 : '8px'}
          >
            {label}
          </FormLabel>
        )}
        {!!labelAction && labelAction}
      </QStack>
      {!!helper && (
        <FormHelperText fontSize="xs" marginBottom="8px" marginTop={0}>
          {helper}
        </FormHelperText>
      )}
      {children}
      {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};
