import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';
import { QTheme } from '../QTheme';

export const ResourceEventTemplates = forwardRef<
  SVGSVGElement,
  Partial<QIconProps>
>((props, ref) => {
  return (
    <Icon viewBox="0 0 16 16" color="currentColor" {...props} ref={ref}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="2" fill={QTheme.colors.gray[100]} />
        <path
          d="M7.583 8a.417.417 0 0 1 .834 0v1.458a.417.417 0 0 1-.834 0zM8 11.125a.417.417 0 1 0 0-.833.417.417 0 0 0 0 .833"
          fill={QTheme.colors.gray[700]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.917 3.833a.417.417 0 1 1 .833 0v.417h2.5v-.417a.417.417 0 0 1 .833 0v.417h1.25c.46 0 .834.373.834.833v6.667c0 .46-.373.833-.834.833H4.667a.833.833 0 0 1-.834-.833V5.083c0-.46.373-.833.834-.833h1.25zm-1.25 3.334v4.583h6.666V7.167z"
          fill={QTheme.colors.gray[700]}
        />
      </svg>
    </Icon>
  );
});

ResourceEventTemplates.displayName = 'ResourceEventTemplates';
