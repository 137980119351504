import React, { useImperativeHandle, useMemo } from 'react';
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowData,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import { QBox } from '../../QLayouts';
import { useDataProvider } from './DataProviders';
import { QTableHeader } from './TableParts/Header';
import { QTableBody } from './TableParts/Body';
import { QTableSpinner } from './TableParts/Spinner';
import { Filters } from './TableParts/Filters';
import { usePaginationForTable } from './Pagination';
import { useSortingForTable } from './Sorting';
import { QCheckboxCell, QCheckboxHeaderCell } from './Cells/Checkbox';
import { useSelectionMaybe } from './Selecting';
import { DataProps, usePartitionedChildren } from '../../utils';
import { QEmptyFilteredTable } from './TableParts/EmptyFilteredTable';
import { useFilteringMaybe } from './Filtering';
import { QEmptyInitiallyTable } from './TableParts/EmptyInitiallyTable';
import { QTableContainer } from './TableParts/Container';
import { useResetPageIndexOnFilterChange } from './useResetPageIndexOnFilterChange';
import { QTableHeaderless } from './TableParts/Headerless';
import { QTableControls, TableControlsProps } from './TableParts/TableControls';
import { QButtonGroup } from '../../QAtoms';

export type QDataTableProps<T extends RowData> = {
  /**
   * The columns to display in the table.
   * Use `createQColumnHelper` to make creating design-complient columns a
   * breeze for your custom data types.
   */
  // Without this `any`, we'll get unreadable TS errors when supplying an invalid type.
  // The `any` will at least give us readable errors about the type that ought to be passed
  // for a given column.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[];
  /**
   * Whether or not to enable sorting on the table.
   * Defaults to `true`.
   */
  enableSorting?: boolean;
  /**
   * A function to get the row ID for a given row.
   */
  getRowId?: (row: T) => string;
  /**
   * A handle for imperative actions on the table.
   * Useful only in a handful of cases.
   * Avoid using it if you can.
   */
  handle?: React.ForwardedRef<QDataTableRef | undefined>;

  children?: React.ReactNode;
  /**
   * Whether or not to display headers in the table.
   * Defaults to `true`.
   */
  withHeaders?: boolean;
} & Pick<TableControlsProps, 'hideItemCount'> &
  DataProps;

export type QDataTableRef = {
  resetSorting: () => void;
};

interface QDataTableExport {
  <T extends RowData>(props: QDataTableProps<T>): React.ReactElement;
  CustomActions: React.FC;
}

export const QDataTable: QDataTableExport = <T extends RowData>({
  columns: rawColumns,
  enableSorting = true,
  getRowId,
  handle,
  children: rawChildren,
  withHeaders = true,
  hideItemCount,
  ...rest
}: QDataTableProps<T>): React.ReactElement => {
  const { data = [], isLoading, isFetchingNextPage } = useDataProvider<T>();
  const pagination = usePaginationForTable();
  const sorting = useSortingForTable();
  const selecting = useSelectionMaybe();
  const filtering = useFilteringMaybe();
  const columns = useMemo(
    () => (selecting === null ? rawColumns : withCheckbox(rawColumns)),
    [selecting, rawColumns],
  );
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: pagination.state
      ? getPaginationRowModel()
      : undefined,
    getSortedRowModel: getSortedRowModel(),
    manualPagination: pagination.manualPagination,
    manualSorting: sorting.manualSorting,
    enableSorting,
    sortDescFirst: false,
    ...stripUndefined({
      getRowId,
      onSortingChange: sorting.onSortingChange,
      onRowSelectionChange: selecting?.setSelection,
      enableRowSelection: selecting?.canSelect,
      state: {
        pagination: pagination.state,
        sorting: sorting.state,
        rowSelection: selecting?.selection,
      },
    } satisfies Partial<TableOptions<T>>),
  });

  useImperativeHandle(
    handle,
    () => ({
      resetSorting: () => {
        table.setSorting([]);
      },
    }),
    [table.setSorting],
  );
  useResetPageIndexOnFilterChange();

  const {
    FilterFormContent,
    QEmptyState: CustomEmptyState,
    FilterCustomFormContent,
    QDataTableCustomActions: CustomActions,
    unmatched: children,
  } = usePartitionedChildren(
    rawChildren,
    'FilterFormContent',
    'QEmptyState',
    'FilterCustomFormContent',
    'QDataTableCustomActions',
  );

  const isTableLoading = isLoading || isFetchingNextPage;
  const isTableEmpty = !isTableLoading && data.length === 0;
  const isTableFiltered =
    !!filtering?.searchTerm ||
    Object.keys(filtering?.filters ?? {}).length !== 0;
  const isInitiallyEmpty = isTableEmpty && !isTableFiltered;

  return (
    <QBox {...rest}>
      {FilterFormContent.length > 0 && <Filters>{FilterFormContent}</Filters>}
      {FilterCustomFormContent.length > 0 && (
        <Filters customFilter>{FilterCustomFormContent}</Filters>
      )}
      <QTableControls hideItemCount={hideItemCount}>
        {CustomActions}
      </QTableControls>
      <QTableContainer table={table} tableBorder={withHeaders}>
        {withHeaders ? (
          <QTableHeader groups={table.getHeaderGroups()} />
        ) : (
          <QTableHeaderless groups={table.getHeaderGroups()} />
        )}
        <QTableBody
          rows={table.getRowModel().rows}
          enableSelecting={!!selecting}
        >
          {isInitiallyEmpty && (
            <QEmptyInitiallyTable colSpan={table.getAllColumns().length}>
              {CustomEmptyState}
            </QEmptyInitiallyTable>
          )}
          {isTableLoading && (
            <QTableSpinner colSpan={table.getAllColumns().length} />
          )}
          {isTableEmpty && isTableFiltered && (
            <QEmptyFilteredTable colSpan={table.getAllColumns().length} />
          )}
        </QTableBody>
      </QTableContainer>
      {!isInitiallyEmpty && <QTableControls hideItemCount={hideItemCount} />}
      {children}
    </QBox>
  );
};

QDataTable.CustomActions = ({ children }) => (
  <QButtonGroup>{children}</QButtonGroup>
);
QDataTable.CustomActions.displayName = 'QDataTableCustomActions';

/**
 * Strips all undefined values from an object, mutating the object in place,
 * and returning the mutated object for convenience.
 *
 * Recurses into nested objects, stripping undefined values from them as well.
 * Does not affect arrays.
 *
 * **Why does this exist?**
 * The `useReactTable` hook knows the difference between
 * an explicit `undefined` and omitting an option, so if we want default
 * behavior for an option, we need to omit it, not set it to `undefined`.
 * This is most evident in the case of onChange callbacks.
 */
const stripUndefined = <T extends Record<string, unknown>>(obj: T): T => {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    }
    if (typeof obj[key] === 'object') {
      stripUndefined(obj[key] as Record<string, unknown>);
    }
  }
  return obj;
};

const withCheckbox = <T extends RowData>(
  columns: ColumnDef<T, unknown>[],
): ColumnDef<T, unknown>[] => {
  const helper = createColumnHelper<T>();
  const checkboxColumn = helper.display({
    id: 'selection',
    enableSorting: false,
    header: QCheckboxHeaderCell,
    cell: QCheckboxCell,
    meta: {
      width: '56px',
    },
  });

  return [checkboxColumn, ...columns];
};
