import { QAlert } from '../../QAtoms';
import React from 'react';
import {
  DataMap,
  DisplayConditions,
  Selectable,
  SelectableDataAccessors,
  Action,
} from './types';

export const ConditionalErrorAlert: React.VFC<{ error: unknown }> = ({
  error,
}) =>
  error && error instanceof Error ? (
    <QAlert
      status="error"
      description={error instanceof Error ? error.message : 'An error occurred'}
    />
  ) : null;

/**
 * Converts an array of selectable items into a map, keyed by the id.
 */
export const toMap = <T,>(
  data: readonly Selectable<T>[],
  idKey: SelectableDataAccessors<T>['id'],
): DataMap<T> => new Map(data.map((datum) => [`${datum[idKey]}`, datum]));

/**
 * Drops any unselected items from the old data and merges in the new data.
 * - Selected items are kept.
 * - Dirty items are kept, but marked hidden if not in the new data.
 * - Dirty flags are preserved.
 * - Field values of new items overwrite old items.
 */
export const dropUnselectedAndMergeNew = <T,>(
  oldData: readonly Selectable<T>[],
  newData: readonly Selectable<T>[],
  idKey: SelectableDataAccessors<T>['id'],
): readonly Selectable<T>[] => {
  const oldDataMap = toMap(oldData, idKey);
  const newDataMap = toMap(newData, idKey);
  const persistedOldData: Selectable<T>[] = [];

  // For items that were previously selected, keep the selected state.
  // If a previously selected item is not in the new data, keep it (we
  // never want to drop selected items).
  // Ensure that isDirty flags are preserved.
  for (const [key, oldDatum] of oldDataMap) {
    const newDatum = newDataMap.get(key);
    if (newDatum) {
      // If an old record also exists in the new record, persist the
      // selected state and dirty flag.
      // Note that this will discard the isHidden flag.
      newDatum.__isSelected = oldDatum.__isSelected;
      newDatum.__isDirty = oldDatum.__isDirty;
    } else if (oldDatum.__isSelected) {
      // If an old record is no longer in the new record, but was selected,
      // keep it.
      persistedOldData.push(oldDatum);
    } else if (oldDatum.__isDirty) {
      // If an old record is no longer in the new record, and was dirty,
      // keep it but mark it as hidden.
      persistedOldData.push({ ...oldDatum, __isHidden: true });
    }
  }

  return [...persistedOldData, ...newDataMap.values()];
};

/**
 * Returns the selected items from the list of selections.
 */
export const getSelected = <T,>(selections: readonly Selectable<T>[]): T[] =>
  selections.filter((item) => item.__isSelected);

/**
 * Returns a copy of the given array with all items marked as dirty.
 */
export const toggleAllDirty = <T,>(
  selections: readonly Selectable<T>[],
): Selectable<T>[] =>
  selections.map((s) => ({ ...s, __isDirty: !s.__isDirty }));

export const resolveDisplayConditions = (
  dataCount: number,
  selectedDataCount: number,
  isDataProviderLoading: boolean,
  currentSearchTerm: string,
  lastAction: Action,
): DisplayConditions => {
  const isDataPresent = dataCount !== 0;
  const isSearchTermPresent = currentSearchTerm !== '';
  const isAllselected = isDataPresent && selectedDataCount === dataCount;

  const isBlankState = !isDataPresent && !isSearchTermPresent;
  const isEditingState =
    isAllselected && !isSearchTermPresent && lastAction === 'typing';
  const noResultState =
    (!isDataPresent || isAllselected) &&
    isSearchTermPresent &&
    lastAction === 'typing';

  const shouldDisplayTable = isDataPresent;
  const shouldDisplaySpinner = isDataProviderLoading;
  const shouldDisplayBlankState =
    !isDataProviderLoading && (isBlankState || isEditingState);
  const shouldDisplayNoResultState = !isDataProviderLoading && noResultState;

  return {
    shouldDisplayTable,
    shouldDisplaySpinner,
    shouldDisplayBlankState,
    shouldDisplayNoResultState,
  };
};
