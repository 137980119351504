import {
  MenuDivider,
  MenuDividerProps,
  MenuGroup,
  MenuGroupProps,
  MenuItem,
  MenuItemProps,
} from '@chakra-ui/react';
import React from 'react';

export type QMenuItemProps = MenuItemProps & {
  isDestructive?: boolean;
};

export const QMenuItem: React.FC<QMenuItemProps> = (props) => {
  const { isDestructive, children, ...rest } = props;
  return (
    <MenuItem color={isDestructive ? 'red.500' : 'inherit'} {...rest}>
      {children}
    </MenuItem>
  );
};

export interface QMenuGroupProps extends MenuGroupProps {
  title: string;
}

export const QMenuGroup: React.FC<QMenuGroupProps> = (props) => {
  const { children, title, ...rest } = props;
  return (
    <MenuGroup {...rest} title={title}>
      {children}
    </MenuGroup>
  );
};

export type QMenuDividerProps = MenuDividerProps;

export const QMenuDivider: React.FC<QMenuDividerProps> = (props) => {
  return <MenuDivider {...props} />;
};
