import React from 'react';
import { QBox, QFlex, QSpacer, QStack } from '../../QLayouts';
import { QDivider } from '../../QAtoms';
import { usePartitionedChildren } from '../../utils/partitionChildren';

export type QHeaderProps = {
  showDivider?: boolean;
};

export const QHeader: React.FC<QHeaderProps> = ({
  children,
  showDivider = true,
}) => {
  const {
    QBackLink,
    QTabs,
    QTitle,
    QSubtitle,
    QButtonGroup,
    QHorizontalMetadata,
    QAlert,
    QApprovalRequestAlert,
  } = usePartitionedChildren(
    children,
    'QBackLink',
    'QTabs',
    'QTitle',
    'QSubtitle',
    'QButtonGroup',
    'QHorizontalMetadata',
    'QAlert',
    'QApprovalRequestAlert',
  );

  return (
    <QStack direction="column" alignSelf="stretch" flexShrink={1} spacing="8px">
      {QBackLink.length && <QBox>{QBackLink}</QBox>}
      <TopRow>{QTabs}</TopRow>
      <QStack spacing="8px">
        <MiddleRow>
          {QTitle}
          {QButtonGroup.length > 0 && (
            <>
              <QSpacer />
              <QBox paddingTop="4px">{QButtonGroup}</QBox>
            </>
          )}
        </MiddleRow>
        {QSubtitle}
      </QStack>
      {QHorizontalMetadata}
      <AlertRow>
        {QAlert}
        {QApprovalRequestAlert}
      </AlertRow>
      {showDivider && <QDivider paddingTop="16px" />}
    </QStack>
  );
};

QHeader.displayName = 'QHeader';

const TopRow: React.FC = ({ children }) =>
  React.Children.count(children) > 0 ? (
    <QBox paddingBottom="8px">{children}</QBox>
  ) : null;

const MiddleRow: React.FC = ({ children }) =>
  React.Children.count(children) > 0 ? (
    <QFlex direction="row">{children}</QFlex>
  ) : null;

const AlertRow: React.FC = ({ children }) =>
  React.Children.count(children) > 0 ? (
    <QStack spacing="8px" paddingTop="8px">
      {children}
    </QStack>
  ) : null;
