import React from 'react';
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
} from '@chakra-ui/react';
import { QIcon, IconNameType } from '../..';

type BaseVariant = 'solid' | 'outline' | 'ghost' | 'link';
type CombinedVariant =
  | BaseVariant
  | `${BaseVariant}-dest`
  | `${BaseVariant}-ai`;

type CommonButtonProps = Pick<
  ButtonProps,
  'isLoading' | 'onClick' | 'size' | 'isDisabled' | 'type' | 'width'
> & {
  variant?: BaseVariant;
  leftIcon?: IconNameType;
  rightIcon?: IconNameType;
};

// The `isDestructive` and `isAI` are mutually exclusive.
type StandardButtonProps = CommonButtonProps & {
  isDestructive?: false;
  isAI?: false;
};

type AIButtonProps = CommonButtonProps & {
  isAI: true;
  isDestructive?: false;
};
type DestructiveButtonProps = CommonButtonProps & {
  isAI?: false;
  isDestructive: true;
};

export type QButtonProps =
  | StandardButtonProps
  | AIButtonProps
  | DestructiveButtonProps;

export type QButtonGroupProps = Pick<ButtonGroupProps, 'isDisabled' | 'size'>;

export const QButton: React.FC<QButtonProps> = ({
  isDestructive = false,
  children,
  variant,
  leftIcon,
  rightIcon,
  isAI = false,
  ...rest
}) => {
  let combinedVariant: CombinedVariant = variant ?? 'solid';
  if (isAI) {
    combinedVariant = `${combinedVariant}-ai`;
  } else if (isDestructive) {
    combinedVariant = `${combinedVariant}-dest`;
  }

  return (
    <Button
      {...rest}
      variant={combinedVariant}
      leftIcon={
        isAI ? (
          <QIcon iconName="QualioAI" />
        ) : leftIcon ? (
          <QIcon iconName={leftIcon} />
        ) : undefined
      }
      rightIcon={
        !isAI && rightIcon ? <QIcon iconName={rightIcon} /> : undefined
      }
    >
      {children ? children : 'Button Label'}
    </Button>
  );
};
QButton.displayName = 'QButton';

export const QButtonGroup: React.FC<QButtonGroupProps> = (props) => {
  return <ButtonGroup {...props} />;
};
QButtonGroup.displayName = 'QButtonGroup';

export type QDestructiveButtonProps = Pick<
  QButtonProps,
  'onClick' | 'isLoading' | 'isDisabled'
>;
export const QDestructiveButton: React.FC<QDestructiveButtonProps> = (
  props,
) => <QButton variant="outline" isDestructive {...props} />;
QDestructiveButton.displayName = 'QDestructiveButton';
