import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';
import { QTheme } from '../QTheme';

export const ResourceUsers = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 16 16" color="currentColor" {...props} ref={ref}>
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill={QTheme.colors.gray[100]} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 3.833A2.083 2.083 0 1 0 8 8a2.083 2.083 0 0 0 0-4.167M6.333 8.833a2.083 2.083 0 0 0-2.083 2.084v.833c0 .23.187.417.417.417h6.666c.23 0 .417-.187.417-.417v-.833a2.083 2.083 0 0 0-2.083-2.084z"
            fill={QTheme.colors.gray[700]}
          />
        </svg>
      </Icon>
    );
  },
);

ResourceUsers.displayName = 'ResourceUsers';
