import React from 'react';
import { QBadge } from '../../QAtoms';
import { QDataColumn } from '../../QAtoms/Table';
import { CellRenderProps } from './CellRenderProps';
import { TextCell } from './Cells/Text';

export type DataView<T> = {
  [K in keyof T]?: {
    /**
     * The text to display in the header of the column.
     */
    header: string;
    /**
     * A custom renderer for the cell.
     * @param value The value of the cell.
     * @param original The original item, for convenience in more complex use cases.
     */
    render?: (value: T[K], original: Readonly<T>) => React.ReactNode;
  } & Pick<QDataColumn, 'width'>;
};

export type ExampleViewData = {
  fullName: string;
  email: string;
  groups: readonly string[];
};

export const ExampleView: DataView<ExampleViewData> = {
  fullName: {
    header: 'Full name',
  },
  email: {
    header: 'Email',
  },
  groups: {
    header: 'Groups',
    render: (groups) =>
      groups.map((g) => (
        <QBadge mx="2px" key={g}>
          {g}
        </QBadge>
      )),
  },
};

const keysOf = <T,>(view: DataView<T>) => Object.keys(view) as (keyof T)[];

/**
 * Convert a DataView into a list of QDataColumns.
 */
export const toColumns = <T,>(view: DataView<T>): QDataColumn[] =>
  keysOf(view)
    .map((key): QDataColumn | null => {
      const definition = view[key];
      if (!definition) {
        return null;
      }
      const { header, render, width } = definition;
      return {
        id: String(key),
        accessor: String(key),
        Header: header,
        width,
        Cell: render
          ? ({ row: { original } }: CellRenderProps<T>) =>
              render(original[key], original)
          : TextCell,
        disableSortBy: true,
      };
    })
    .filter(Boolean) as QDataColumn[];
