import {
  Size,
  ChakraStylesConfig,
  StylesConfig,
  Props,
} from 'chakra-react-select';
import colors from '../../QTheme/colors';
import { QSelectItem } from './types';

export const chakraStyles: ChakraStylesConfig<QSelectItem> = {
  control: (provided) => ({
    ...provided,
    color: 'gray.700',
    fontWeight: 'regular',
    borderColor: 'blue.200',
    borderRadius: '4px',
    _focus: {
      borderColor: 'blue.400',
      boxShadow: `${colors.blue['400']} 0px 0px 0px 1px`,
    },
    _hover: {
      borderColor: 'blue.400',
    },
    _disabled: {
      color: 'gray.400',
      borderColor: 'gray.400',
    },
    _invalid: {
      _focus: {
        borderColor: 'red.500',
        boxShadow: `${colors.red['500']} 0px 0px 0px 1px`,
      },
      borderWidth: '1px',
      boxShadow: `${colors.red['500']} 0px 0px 0px 1px`,
      borderColor: 'red.500',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'gray.700',
    backgroundColor: '#fff',
    borderWidth: 0,
    paddingInlineStart: 0,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    marginInline: 0,
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    marginRight: '6px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    borderWidth: 0,
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#fff',
    borderWidth: '1px',
    borderColor: 'gray.200',
    borderRadius: '4px',
    boxShadow: 'md',
  }),
  menuList: (provided) => ({
    ...provided,
    borderWidth: '0',
    minWidth: '0',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'gray.500',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
  groupHeading: (provided) => ({
    ...provided,
    borderTop: '1px solid var(--chakra-colors-gray-200); !important',
    marginTop: '8px !important',
    fontWeight: '600 !important',
    fontSize: 'sm',
    padding: '0.3rem 0.6rem',
    marginInlineStart: '0.8rem',
  }),
};

export const styles: StylesConfig<QSelectItem<React.ReactChild, unknown>> = {
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
};

export const baseProps = {
  chakraStyles: chakraStyles,
  styles: styles,
  size: 'sm' as Size,
} as Props<QSelectItem<React.ReactChild, unknown>>;
