import { QIcon } from '../../QIcons';
import {
  QButton,
  QButtonGroup,
  QDivider,
  QIconButton,
  QText,
} from '../../QAtoms';
import { QStack } from '../../QLayouts';
import { FileListItem, QFileInputValue } from './types';
import React, { useMemo, useState } from 'react';
import { QDeleteConfirmationModal } from '..';

const FileItem: React.FC<
  { file: FileListItem } & Pick<FileListProps, 'onDelete' | 'onDownload'>
> = ({ file, onDelete, onDownload }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <>
      <QStack
        alignItems="center"
        direction="row"
        justify="space-between"
        data-cy={`attachment-record-${file.id}`}
      >
        <QStack direction="row">
          <QText fontSize="sm" fontWeight="semibold">
            {file.filename}
          </QText>
          {file.errorMessage && (
            <QStack direction="row" alignItems="center">
              <QIcon iconName="FillXCircleFill" color="red.500" />
              <QText fontSize="sm" color="red.500">
                {file.errorMessage}
              </QText>
            </QStack>
          )}
        </QStack>
        <QButtonGroup>
          {onDownload && (
            <QIconButton
              iconName="Download"
              data-cy={`attachment-download-${file.id}`}
              aria-label="Download attachment"
              onClick={() => onDownload(file)}
            />
          )}
          <QButton
            aria-label="file-remove-button"
            data-cy={`attachment-remove-${file.id}`}
            variant="ghost"
            onClick={() =>
              !file.errorMessage ? setShowConfirmation(true) : onDelete(file)
            }
          >
            <QIcon iconName="Trash" color="red.500" />
          </QButton>
        </QButtonGroup>
      </QStack>
      {showConfirmation && (
        <QDeleteConfirmationModal
          onConfirm={() => onDelete(file)}
          onClose={() => setShowConfirmation(false)}
          title="Delete file"
          message={
            <QText>
              Are you sure you want to delete this file? This action cannot be
              undone.
            </QText>
          }
        />
      )}
    </>
  );
};

type FileListProps = {
  onDelete: (value: QFileInputValue) => void;
  onDownload?: (value: QFileInputValue) => void;
  files: readonly FileListItem[];
};

export const FileItemList: React.FC<FileListProps> = ({
  onDelete,
  onDownload,
  files,
}) => {
  const joinedComponents = useMemo(() => {
    return files.reduce(
      (
        acc: readonly React.ReactElement[],
        current: QFileInputValue,
        index: number,
      ) => {
        if (index === 0) {
          return [
            <FileItem
              file={current}
              onDelete={onDelete}
              onDownload={onDownload}
              key={current.id}
            />,
          ];
        }

        return [
          ...acc,
          <QDivider key={`${current.id}-divider`} />,
          <FileItem
            file={current}
            onDelete={onDelete}
            onDownload={onDownload}
            key={current.id}
          />,
        ];
      },
      [],
    );
  }, [files, onDelete, onDownload]);

  return (
    <QStack
      border="1px"
      borderRadius="4px"
      padding="15px"
      borderColor="gray.200"
    >
      {joinedComponents}
    </QStack>
  );
};
