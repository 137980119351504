import React, { useCallback } from 'react';
import {
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QModalProps,
} from '../../../QComponents';
import { QButtonGroup, QTitle, QButton } from '../../../QAtoms';
import { useFiltering } from './context';
import { FilterDefinitions } from './types';
import { useFilteringForm } from './useForm';
import { FormProvider } from 'react-hook-form';

export type ModalProps = Pick<QModalProps, 'isOpen' | 'onClose'> & {
  children: React.ReactNode;
};

export const FilterModal = <T extends FilterDefinitions>({
  isOpen,
  onClose,
  children,
}: ModalProps): React.ReactElement => {
  const filterContext = useFiltering<T>();
  const methods = useFilteringForm<T>();

  const onSubmit = useCallback(
    (values) => {
      filterContext.setFilters(values);
      onClose();
    },
    [filterContext.setFilters, onClose],
  );

  const clearAllFilters = useCallback(() => {
    for (const key in methods.getValues()) {
      methods.setValue(key, null);
    }
  }, [methods.getValues, methods.setValue]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onClose}>
        <QModal size="lg" isOpen={isOpen} onClose={onClose}>
          <QModalHeader>
            <QTitle>Filter</QTitle>
          </QModalHeader>
          <QModalBody>{children}</QModalBody>
          <QModalActions>
            <QButtonGroup>
              <QButton variant="ghost" onClick={clearAllFilters}>
                Clear
              </QButton>
            </QButtonGroup>
            <QButton onClick={onClose} variant="outline">
              Cancel
            </QButton>
            <QButton onClick={methods.handleSubmit(onSubmit)}>
              Apply filters
            </QButton>
          </QModalActions>
        </QModal>
      </form>
    </FormProvider>
  );
};
FilterModal.DisplayName = 'FilteringModal';
