import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';
import { QTheme } from '../QTheme';

export const ResourceEvents = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 16 16" color="currentColor" {...props} ref={ref}>
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="16"
            height="16"
            rx="2"
            fill={QTheme.colors.orange[100]}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.333 3.417a.417.417 0 0 0-.416.416v.417h-1.25a.833.833 0 0 0-.834.833v6.667c0 .46.373.833.834.833h6.666c.46 0 .834-.373.834-.833V5.083a.833.833 0 0 0-.834-.833h-1.25v-.417a.417.417 0 0 0-.833 0v.417h-2.5v-.417a.417.417 0 0 0-.417-.416M4.667 5.083h1.25V5.5a.417.417 0 1 0 .833 0v-.417h2.5V5.5a.417.417 0 0 0 .833 0v-.417h1.25v1.25H4.667zM7.583 8a.417.417 0 0 1 .834 0v1.458a.417.417 0 1 1-.834 0zM8 11.125a.417.417 0 1 0 0-.833.417.417 0 0 0 0 .833"
            fill={QTheme.colors.orange[900]}
          />
        </svg>
      </Icon>
    );
  },
);

ResourceEvents.displayName = 'ResourceEvents';
