import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';
import { QTheme } from '../QTheme';

export const ResourceRegistry = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 16 16" color="currentColor" {...props} ref={ref}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
          <rect width="16" height="16" fill={QTheme.colors.gray[100]} rx="2" />
          <path
            fill={QTheme.colors.gray[700]}
            fillRule="evenodd"
            d="M11.333 6.136q-.252.128-.55.227c-.731.243-1.715.387-2.783.387s-2.052-.144-2.783-.387a4 4 0 0 1-.55-.227V8l.001.008.013.027a.5.5 0 0 0 .12.126c.137.108.36.223.675.328.624.208 1.514.344 2.524.344s1.9-.136 2.524-.344c.314-.105.538-.22.674-.328a.5.5 0 0 0 .12-.126l.014-.027.001-.008zm.834-1.053c0-.355-.217-.626-.454-.813-.242-.191-.566-.345-.93-.466-.731-.244-1.715-.387-2.783-.387s-2.052.143-2.783.387c-.364.121-.688.275-.93.466-.237.187-.454.458-.454.813v5.834c0 .354.214.626.451.813.241.191.565.345.928.466.73.244 1.714.387 2.788.387s2.058-.143 2.788-.387c.363-.12.687-.274.928-.466.237-.187.45-.46.45-.813zm-.834 3.972c-.165.085-.35.16-.545.225-.73.243-1.714.387-2.788.387s-2.058-.144-2.788-.387a4 4 0 0 1-.545-.225v1.862l.001.007.013.028a.5.5 0 0 0 .12.125c.137.108.36.224.675.329.624.208 1.514.344 2.524.344s1.9-.136 2.524-.344c.314-.105.538-.22.674-.329a.5.5 0 0 0 .12-.125l.014-.028.001-.007z"
            clipRule="evenodd"
          />
        </svg>
      </Icon>
    );
  },
);

ResourceRegistry.displayName = 'ResourceRegistry';
