import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';
import { QTheme } from '../QTheme';

export const ResourceCR = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 16 16" color="currentColor" {...props} ref={ref}>
        <svg fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="16" height="16" rx="2" fill={QTheme.colors.pink[100]} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m11.749 6.224-.005-.031V6.19l-.007-.031v-.003l-.011-.029-.002-.003-.012-.028-.002-.004-.016-.026-.003-.003-.018-.025-.003-.003q-.01-.013-.023-.025L9.988 4.35a.352.352 0 0 0-.602.248v1.308H4.602a.352.352 0 0 0 0 .703h4.784v1.308a.352.352 0 0 0 .602.248l1.66-1.66.023-.025.002-.002.018-.026.003-.004.015-.026.001-.002.001-.002.013-.027.001-.003.01-.03.001-.002.008-.031v-.002l.004-.031V6.29l.002-.033q0-.015-.002-.032zM4.251 9.776l.005.031v.002l.007.031.001.003.01.029.002.003.012.028.002.004.016.026.003.003.018.025.003.003.023.025 1.659 1.66a.352.352 0 0 0 .602-.248v-1.308h4.784a.352.352 0 0 0 0-.703H6.614V8.082a.352.352 0 0 0-.602-.248l-1.66 1.66-.023.025-.002.002-.018.026-.003.004-.015.026-.001.002-.001.002-.013.027-.001.003-.01.03-.001.002-.008.031v.002l-.004.031v.002l-.002.033q0 .015.002.032z"
            fill={QTheme.colors.pink[900]}
          />
        </svg>
      </Icon>
    );
  },
);

ResourceCR.displayName = 'ResourceCR';
