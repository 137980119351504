import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DataProps } from '../../utils';
import { QRadio } from '../../QAtoms';
import {
  QDataColumn,
  QDataRow,
  QDataTable,
  QDefaultSortingColumn,
} from '../../QAtoms/Table';
import { DataView, toColumns } from './View';
import { CellRenderProps } from './CellRenderProps';
import { SelectableDataAccessors, Action } from './types';
import { QSpinner } from '../../QAtoms/Spinner/Spinner';
import { QModalFullBody } from '../../QComponents/Modal/ModalFullBody';
import { QEmptyState } from '../../QComponents/EmptyState/EmptyState';
import { resolveDisplayConditions } from './SelectHelpers';
import { QBox } from '../../QLayouts';

/**
 * In these props, the generic type `V` is the data required to be displayed in the list.
 * The generic type `T` is the type of the data that is selected, and is a superset of `V`.
 */
export type QSelectableRadioListProps<V, T extends V = V> = {
  data: readonly T[];

  /** Callback invoked when the user changes a selection.
   *  Only the changed items are passed in the callback.
   */
  onDataChange: (changes: readonly T[]) => void;

  /** Keys for the id and the selected state.
   *  The `id` key is used to uniquely identify each item.
   *  The `selected` key is used to determine if an item is selected.
   *  The `disabled` key is used to determine if an item is disabled.
   */
  accessors: SelectableDataAccessors<T>;

  /** The view to be displayed in the list.
   * Defines the columns to be shown.
   */
  view: DataView<V>;

  defaultSortBy?: QDefaultSortingColumn;

  /**
   * Indicates if the data is currently being loaded from API.
   */
  isDataLoading: boolean;

  /**
   * Current search term that has been set by the user.
   */
  currentSearchTerm: string;
} & DataProps;

export function QSelectableRadioList<V, T extends V = V>({
  data,
  onDataChange,
  accessors,
  view,
  defaultSortBy,
  isDataLoading,
  currentSearchTerm,
  ...dataProps
}: QSelectableRadioListProps<V, T>): React.ReactElement {
  const isSelected = useCallback(
    (t: T): boolean => !!t[accessors.selected],
    [accessors.selected],
  );

  const [lastAction, setLastAction] = useState<Action>('typing');
  useEffect(() => setLastAction('typing'), [currentSearchTerm]);

  const onChange = useCallback(
    (changed: T) => {
      setLastAction('selecting');

      // Set all items to not selected except the changed one
      onDataChange(
        data.map((item) =>
          item[accessors.id] === changed[accessors.id]
            ? { ...item, [accessors.selected]: true }
            : { ...item, [accessors.selected]: false },
        ),
      );
    },
    [setLastAction, onDataChange, data],
  );

  const radioCell = useCallback(
    ({ row: { original } }: CellRenderProps<T>) => (
      <ItemRadio item={original} onChange={onChange} accessors={accessors} />
    ),
    [onChange, accessors],
  );

  const columns = useMemo(
    (): QDataColumn[] => [
      {
        id: 'selected',
        disableSortBy: true,
        width: 0,
        accessor: accessors.selected,
        Cell: radioCell,
      },
      ...toColumns(view),
    ],
    [radioCell, data, onChange],
  );

  const {
    shouldDisplayTable,
    shouldDisplaySpinner,
    shouldDisplayBlankState,
    shouldDisplayNoResultState,
  } = useMemo(
    () =>
      resolveDisplayConditions(
        data.length,
        data.filter(isSelected).length,
        isDataLoading,
        currentSearchTerm,
        lastAction,
      ),
    [data, isDataLoading, currentSearchTerm, lastAction],
  );

  return (
    <div {...dataProps}>
      <QBox hidden={!shouldDisplayTable}>
        <QDataTable
          columns={columns}
          data={data as unknown as QDataRow[]} // FIXME: This type of assertion is to be avoided.
          hasPagination={false}
          manualSortBy={
            defaultSortBy ? { defaultSortByColumn: [defaultSortBy] } : undefined
          }
        />
      </QBox>

      {shouldDisplaySpinner && (
        <QModalFullBody>
          <QSpinner />
        </QModalFullBody>
      )}

      {shouldDisplayBlankState && (
        <QModalFullBody>
          <QEmptyState title="Type to start searching..." />
        </QModalFullBody>
      )}

      {shouldDisplayNoResultState && (
        <QModalFullBody>
          <QEmptyState
            title="No results found"
            subtitle="We couldn't find any matches, please try again."
          />
        </QModalFullBody>
      )}
    </div>
  );
}

type ItemRadioProps<T> = {
  item: Readonly<T>;
  onChange: (item: T) => void;
  accessors: SelectableDataAccessors<T>;
};

const ItemRadio = <T,>({
  item,
  onChange,
  accessors,
}: ItemRadioProps<T>): React.ReactElement => (
  <QRadio
    onChange={(e) => e.target.checked && onChange(item)}
    isChecked={!!item[accessors.selected]}
    isDisabled={!!item[accessors.disabled]}
    data-cy={`selectable-list-radio-${item[accessors.id]}`}
  />
);
