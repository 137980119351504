import React from 'react';
import { Wrap, WrapItem } from '@chakra-ui/react';
import { QDotSeparator } from '../../QAtoms';
import { partitionChildren } from '../../utils';

/**
 * This components displays metadata items horizontally with a dot separator.
 *
 * It decides the order of the special metadata children and puts any arbitrary children at the end.
 *
 * `QTag`s (which should be used to show a status) are always displayed first and do not have a separator following them.
 */
export const QHorizontalMetadata: React.FC = ({ children }) => {
  const {
    QTag,
    QVersionMetadata,
    QOwnerMetadata,
    QCreatedAtMetadata,
    QLastModifiedMetadata,
    QDueMetadata,
    unmatched,
  } = partitionChildren(
    children,
    'QTag',
    'QVersionMetadata',
    'QOwnerMetadata',
    'QCreatedAtMetadata',
    'QLastModifiedMetadata',
    'QDueMetadata',
  );

  const dividedChildren = [
    QVersionMetadata,
    QOwnerMetadata,
    QCreatedAtMetadata,
    QLastModifiedMetadata,
    QDueMetadata,
    unmatched,
  ]
    .flat()
    .filter(shouldRender)
    .flatMap((item, i) => [
      item,
      <WrapItem key={`separator-${item.key ?? i}`}>
        <QDotSeparator />
      </WrapItem>,
    ])
    .slice(0, -1);

  const tags = QTag.map((tag, i) => (
    <WrapItem key={`tag-${tag.key ?? i}`} paddingRight="4px">
      {tag}
    </WrapItem>
  ));

  return (
    <Wrap spacing="4px" align="baseline">
      {tags}
      {dividedChildren}
    </Wrap>
  );
};

QHorizontalMetadata.displayName = 'QHorizontalMetadata';

/**
 * An item should be rendered if any of the following conditions are met:
 * - It is not a metadata item (i.e. it does not have a `value` prop)
 * - It is a metadata item and it has a `value` prop that is not `undefined` or `null`
 */
const shouldRender = (item: React.ReactElement) =>
  !item.props ||
  typeof item.props !== 'object' ||
  !('value' in item.props) ||
  (item.props.value !== undefined && item.props.value !== null);
