import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';
import { QTheme } from '../QTheme';

export const ResourceDocumentTemplates = forwardRef<
  SVGSVGElement,
  Partial<QIconProps>
>((props, ref) => {
  return (
    <Icon viewBox="0 0 16 16" color="currentColor" {...props} ref={ref}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="2" fill={QTheme.colors.gray[100]} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.616 3.783a1.25 1.25 0 0 1 .884-.366h3.333c.11 0 .217.044.295.122l2.5 2.5a.42.42 0 0 1 .122.294v5a1.25 1.25 0 0 1-1.25 1.25h-5a1.25 1.25 0 0 1-1.25-1.25V4.667c0-.332.132-.65.366-.884m.884.467a.417.417 0 0 0-.417.417v6.666a.417.417 0 0 0 .417.417h5a.417.417 0 0 0 .417-.417V6.75H8.833a.417.417 0 0 1-.416-.417V4.25zm.417 2.5c0-.23.186-.417.416-.417h.834a.417.417 0 0 1 0 .834h-.834a.417.417 0 0 1-.416-.417m0 1.667c0-.23.186-.417.416-.417h3.334a.417.417 0 0 1 0 .833H6.333a.417.417 0 0 1-.416-.416m0 1.666c0-.23.186-.416.416-.416h3.334a.417.417 0 0 1 0 .833H6.333a.417.417 0 0 1-.416-.417"
          fill={QTheme.colors.gray[700]}
        />
      </svg>
    </Icon>
  );
});

ResourceDocumentTemplates.displayName = 'ResourceDocumentTemplates';
