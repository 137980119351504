import React, { useMemo } from 'react';
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
  MenuListProps,
  MenuProps,
} from '@chakra-ui/react';
import { QButtonProps, QIcon, QText } from '../..';
import { IconNameType } from '../../types/Icons';
import { DataProps } from '../..';

export type QMenuItemType = {
  id: string;
  label: string;
  isDestructive?: boolean;
};

export type QMenuButtonDefaultProps = Pick<MenuProps, 'onClose' | 'isOpen'> &
  DataProps & {
    buttonLabel?: string;
    iconName?: IconNameType;
    variant?: QButtonProps['variant'] | 'icon';
    itemSize?: 'xs' | 'sm' | 'md' | 'lg';
    isDisabled?: boolean;
    maxWidth?: MenuButtonProps['maxWidth'];
    maxHeight?: MenuListProps['maxHeight'];
  };

type QMenuButtonWithPropsType = {
  items: readonly QMenuItemType[];
  onItemClick: (item: QMenuItemType) => void;
} & QMenuButtonDefaultProps;

type QMenuButtonWithCompositeType = {
  items?: never;
  onItemClick?: never;
  children: React.ReactNode;
} & QMenuButtonDefaultProps;

export type QMenuButtonProps =
  | QMenuButtonWithPropsType
  | QMenuButtonWithCompositeType;

export const QMenuButton: React.FC<QMenuButtonProps> = (props) => {
  const {
    buttonLabel,
    variant = 'solid',
    itemSize = 'sm',
    iconName,
    children,
    isOpen,
    isDisabled,
    onClose,
    maxHeight,
    items,
    onItemClick,
    ...rest
  } = props;

  const maxHeightProps: MenuListProps = maxHeight
    ? { maxHeight, overflowY: 'auto', overflowX: 'hidden' }
    : {};

  const menuItems = useMemo(
    () => (items: readonly QMenuItemType[]) =>
      items.map((item) => {
        const { id, label, ...otherParams } = item;
        return (
          <MenuItem
            {...otherParams}
            key={id}
            onClick={onItemClick ? () => onItemClick(item) : undefined}
            fontSize={itemSize}
            color={item.isDestructive ? 'red.500' : 'inherit'}
          >
            {label}
          </MenuItem>
        );
      }),
    [items, itemSize, onItemClick],
  );

  if (variant === 'icon') {
    return (
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton
          items={items}
          isDisabled={isDisabled}
          as={IconButton}
          aria-label={buttonLabel}
          icon={<QIcon iconName={iconName ?? 'MoreVertical'} />}
          variant="ghost"
          {...rest}
        >
          {children}
        </MenuButton>
        <MenuList {...maxHeightProps}>
          {items ? menuItems(items) : children}
        </MenuList>
      </Menu>
    );
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant={variant}
        isDisabled={isDisabled}
        rightIcon={<QIcon iconName={iconName ?? 'ChevronDown'} />}
        {...rest}
      >
        <QText isTruncated>{buttonLabel}</QText>
      </MenuButton>
      <MenuList {...maxHeightProps}>
        {items ? menuItems(items) : children}
      </MenuList>
    </Menu>
  );
};
