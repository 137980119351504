import React, { useState } from 'react';
import { QFlex, QSpacer } from '../../QLayouts';
import {
  QButtonGroup,
  QButton,
  QAlertBox,
  QAlertTitle,
  QAlertDescription,
} from '../../QAtoms';

export type QApprovalRequestAlertProps = {
  onApprove: () => void | Promise<unknown>;
  onReject: () => void | Promise<unknown>;
};

/**
 * A pre-made alert for approval requests.
 *
 * Wraps `QAlertBox` and adds approve and decline buttons.
 *
 * If an action is async, the button will enter a loading state until the promise settles.
 * This component does not handle the promise rejection, it is up to the parent to handle it.
 * If the promise is rejected, this component will not throw the error.
 */
export const QApprovalRequestAlert: React.VFC<QApprovalRequestAlertProps> = ({
  onApprove,
  onReject,
}) => {
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const approve = async () => {
    setIsApproving(true);
    const result = onApprove();
    if (result instanceof Promise) {
      result?.finally(() => setIsApproving(false));
    } else {
      setIsApproving(false);
    }
  };
  const reject = async () => {
    setIsRejecting(true);
    const result = onReject();
    if (result instanceof Promise) {
      result?.finally(() => setIsRejecting(false));
    } else {
      setIsRejecting(false);
    }
  };
  return (
    <QAlertBox status="warning" alignItems="center">
      <QFlex alignItems="baseline">
        <QAlertTitle>Approval request</QAlertTitle>
        <QAlertDescription>Your approval is required</QAlertDescription>
        <QSpacer />
        <QButtonGroup>
          <QButton
            isDestructive
            onClick={reject}
            isDisabled={isApproving}
            isLoading={isRejecting}
            data-cy="decline-btn"
          >
            Decline
          </QButton>
          <QButton
            onClick={approve}
            isDisabled={isRejecting}
            isLoading={isApproving}
            data-cy="approve-btn"
          >
            Approve
          </QButton>
        </QButtonGroup>
      </QFlex>
    </QAlertBox>
  );
};
QApprovalRequestAlert.displayName = 'QApprovalRequestAlert';
