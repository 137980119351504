import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';
import { QTheme } from '../QTheme';

export const ResourceSuppliers = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 16 16" color="currentColor" {...props} ref={ref}>
        <svg fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="16" height="16" rx="2" fill={QTheme.colors.teal[100]} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 4.25c0-.23.187-.417.417-.417h6.25c.23 0 .416.187.416.417v1.667h1.25c.11 0 .217.044.295.122l1.25 1.25a.42.42 0 0 1 .122.294v2.084c0 .23-.187.416-.417.416h-.557a1.458 1.458 0 1 1-2.636 0H6.61a1.458 1.458 0 1 1-2.636 0h-.557A.417.417 0 0 1 3 9.667zm7.083 5v-2.5h1.078l1.006 1.006V9.25zm-4.791.833a.625.625 0 1 0 0 1.25.625.625 0 0 0 0-1.25m5.416 0a.625.625 0 1 0 0 1.25.625.625 0 0 0 0-1.25"
            fill={QTheme.colors.teal[900]}
          />
        </svg>
      </Icon>
    );
  },
);

ResourceSuppliers.displayName = 'ResourceSuppliers';
