import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';
import { QTheme } from '../QTheme';

export const ResourceTrainingPlans = forwardRef<
  SVGSVGElement,
  Partial<QIconProps>
>((props, ref) => {
  return (
    <Icon viewBox="0 0 16 16" color="currentColor" {...props} ref={ref}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="2" fill={QTheme.colors.gray[100]} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.542 3.833a2.083 2.083 0 1 0 0 4.167 2.083 2.083 0 0 0 0-4.167M12.878 7.045a.417.417 0 1 0-.59-.59l-1.371 1.372-.54-.538a.417.417 0 1 0-.588.589l.833.833a.417.417 0 0 0 .59 0zM5.083 8.833A2.083 2.083 0 0 0 3 10.917v.833c0 .23.187.417.417.417h6.25c.23 0 .416-.187.416-.417v-.833A2.083 2.083 0 0 0 8 8.833z"
          fill={QTheme.colors.gray[700]}
        />
      </svg>
    </Icon>
  );
});

ResourceTrainingPlans.displayName = 'ResourceTrainingPlans';
