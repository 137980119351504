import React from 'react';
import { QStack, QBox } from '../../QLayouts';
import { QLink } from '../../QAtoms';
import { QIcon } from '../../QIcons';
import { SearchDomain, SearchResource } from '../../types/domains/Search';
import { IconNameType } from '../../types/Icons';
import { DataProps } from '../../utils';

type domainOrResourceType = SearchDomain | SearchResource;

export const domainOrResourceIconMap = {
  // resourceTypes
  'change-control': 'ResourceCR',
  'change-request': 'ResourceCR',
  'dc-requirement': 'ResourceDesignControls',
  'dc-testCase': 'ResourceDesignControls',
  'dc-change-control': 'ResourceDesignControls',
  'dc-risk': 'ResourceDesignControls',
  document: 'ResourceDocuments',
  'document-template': 'ResourceDocumentTemplates',
  'quality-event': 'ResourceEvents',
  'event-template': 'ResourceEventTemplates',
  'registry-item': 'ResourceRegistry',
  'registry-category': 'ResourceRegistry',
  supplier: 'ResourceSuppliers',
  'training-plan': 'ResourceTrainingPlans',
  user: 'ResourceUsers',
  // domains
  'change-management': 'ResourceCR',
  'change-requests': 'ResourceCR',
  'design-controls': 'ResourceDesignControls',
  documents: 'ResourceDocuments',
  'quality-events': 'ResourceEvents',
  registry: 'ResourceRegistry',
  suppliers: 'ResourceSuppliers',
  training: 'ResourceTrainingPlans',
  users: 'ResourceUsers',
} satisfies { [key in domainOrResourceType]: IconNameType };

export type QResourceLabelProps = {
  /** Either `domain` or `resourceType` values from the Common Resource Format can be used. `resourceType` is preferred. */
  domain: domainOrResourceType;
  link?: string;
  openInNewTab?: boolean;
  onClick?: () => void;
} & DataProps;

/**
 * This components displays informations about linked Qualio object e.g. Event, Document, Supplier, User.
 */
export const QResourceLabel: React.FC<QResourceLabelProps> = ({
  domain,
  link,
  openInNewTab,
  children,
  onClick,
  ...dataProps
}) => (
  <QStack direction="row" spacing="8px" alignItems="center" {...dataProps}>
    <QIcon iconName={domainOrResourceIconMap[domain]} />
    <QBox>
      {link === undefined ? (
        children
      ) : (
        <QLink isExternal={openInNewTab} href={link} onClick={onClick}>
          {children}
        </QLink>
      )}
    </QBox>
  </QStack>
);
