export const SidePanel = {
  baseStyle: {},
  sizes: {
    sm: {
      minWidth: '256px',
    },
    md: {
      minWidth: '320px',
    },
  },
  defaultProps: {
    size: 'sm',
  },
};
