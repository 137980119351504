export default {
  blue: {
    900: '#1D2473',
    800: '#FFFFFF', // Not used
    700: '#303CBF',
    600: '#FFFFFF', // Not used
    500: '#3D4CF2',
    400: '#7883FF',
    300: '#FFFFFF', // Not used
    200: '#C9CEFF',
    100: '#DEE1FF',
    50: '#F0F1FF',
  },
  gray: {
    900: '#14151A',
    800: '#14151A',
    700: '#363842',
    600: '#FFFFFF', // Not used
    500: '#565866',
    400: '#C0C2CC',
    300: '#FFFFFF', // Not used
    200: '#D3D4DB',
    100: '#E6E7EB',
    50: '#F7F8FA',
  },
  red: {
    900: '#660005',
    800: '#FFFFFF', // Not used
    700: '#A31A1A',
    600: '#FFFFFF', // Not used
    500: '#D64040',
    400: '#FF7A7A',
    300: '#FFFFFF', // Not used
    200: '#FEC6C6',
    100: '#FFE0E0',
    50: '#FFF0F0',
  },
  yellow: {
    900: '#5C3D00',
    800: '#FFFFFF', // Not used
    700: '#FFFFFF', // Not used
    600: '#FFFFFF', // Not used
    500: '#FCAD00',
    400: '#FCCD3F',
    300: '#FFFFFF', // Not used
    200: '#FFFFFF', // Not used
    100: '#FCECBA',
    50: '#FCF7E3',
  },
  green: {
    900: '#004730',
    800: '#FFFFFF', // Not used
    700: '#FFFFFF', // Not used
    600: '#FFFFFF', // Not used
    500: '#00B277',
    400: '#6ACCAB',
    300: '#FFFFFF', // Not used
    200: '#FFFFFF', // Not used
    100: '#D3F5E9',
    50: '#EDFAF6',
  },
  purple: {
    900: '#321D73',
    800: '#FFFFFF', // Not used
    700: '#5636B9',
    600: '#FFFFFF', // Not used
    500: '#7B4FFF',
    400: '#A98CFF',
    300: '#FFFFFF', // Not used
    200: '#D1C2FF',
    100: '#E6DEFF',
    50: '#F4F0FF',
  },
  pink: {
    900: '#731D5F',
    800: '#FFFFFF', // Not used
    700: '#FFFFFF', // Not used
    600: '#FFFFFF', // Not used
    500: '#D67CC2',
    400: '#ED9ADA',
    300: '#FFFFFF', // Not used
    200: '#FFFFFF', // Not used
    100: '#F7DFF2',
    50: '#FFEDFB',
  },
  orange: {
    900: '#733600',
    800: '#FFFFFF', // Not used
    700: '#FFFFFF', // Not used
    600: '#FFFFFF', // Not used
    500: '#FF7700',
    400: '#FF9D47',
    300: '#FFFFFF', // Not used
    200: '#FFFFFF', // Not used
    100: '#FFDEC2',
    50: '#FFEFE0',
  },
} as const;
