import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';
import { QTheme } from '../QTheme';

export const ResourceDesignControls = forwardRef<
  SVGSVGElement,
  Partial<QIconProps>
>((props, ref) => {
  return (
    <Icon viewBox="0 0 16 16" color="currentColor" {...props} ref={ref}>
      <svg
        width="16"
        height="16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="16" height="16" rx="2" fill={QTheme.colors.cyan[100]} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.814 3.46a.42.42 0 0 1 .372 0l4.167 2.084a.417.417 0 0 1 0 .745L8.186 8.373a.42.42 0 0 1-.372 0L3.647 6.289a.417.417 0 0 1 0-.745zM3.46 7.815a.417.417 0 0 1 .559-.187L8 9.617l3.98-1.99a.417.417 0 0 1 .373.746l-4.167 2.083a.42.42 0 0 1-.372 0L3.647 8.373a.417.417 0 0 1-.186-.56m0 2.083a.417.417 0 0 1 .559-.186L8 11.7l3.98-1.99a.417.417 0 0 1 .373.745l-4.167 2.083a.42.42 0 0 1-.372 0l-4.167-2.083a.417.417 0 0 1-.186-.559"
          fill={QTheme.colors.cyan[900]}
        />
      </svg>
    </Icon>
  );
});

ResourceDesignControls.displayName = 'ResourceDesignControls';
