import React, { useMemo } from 'react';
import {
  Select,
  Props as SelectProps,
  SingleValue,
  MultiValue,
} from 'chakra-react-select';
import { baseProps } from './base';
import { isGroup, QSelectGroup, QSelectItem } from './types';
import { usePartitionedChildren } from '../../utils';

interface QSelectBaseProps<
  isMulti extends boolean = boolean,
  TLabel extends React.ReactChild = string,
  TValue = string,
> extends Pick<
    SelectProps<QSelectItem<TLabel, TValue>, isMulti>,
    | 'isDisabled'
    | 'isInvalid'
    | 'size'
    | 'isClearable'
    | 'name'
    | 'isLoading'
    | 'isSearchable'
    | 'defaultValue'
    | 'filterOption'
    | 'menuPosition'
  > {
  options: readonly (
    | QSelectItem<TLabel, TValue>
    | QSelectGroup<string, TLabel, TValue>
  )[];
  children?: React.ReactNode;
}

export interface QSelectProps<
  TLabel extends React.ReactChild = string,
  TValue = string,
> extends QSelectBaseProps<false, TLabel, TValue> {
  /** Invoked when the value changes. Argument is null when value is cleared. */
  onChange?: (selected: QSelectItem<TLabel, TValue> | null) => void;
  value?: TValue;
  clearInputOnSelect?: boolean;
}

export interface QMultiSelectProps<
  TLabel extends React.ReactChild = string,
  TValue = string,
> extends QSelectBaseProps<true, TLabel, TValue> {
  onChange?: (selected: readonly QSelectItem<TLabel, TValue>[]) => void;
  value?: readonly TValue[];
}

export const QSelect = <
  TLabel extends React.ReactChild = string,
  TValue = string,
>(
  props: QSelectProps<TLabel, TValue>,
): React.ReactElement => {
  const { onChange, value, options, clearInputOnSelect, children } = props;

  const onChangeHandler = (
    selected: SingleValue<QSelectItem<TLabel, TValue>>,
  ) => {
    onChange && onChange(selected);
  };

  const selectedValue = useMemo(() => {
    for (const o of options) {
      if (isGroup(o)) {
        const found = o.options.find((subOption) => subOption.value === value);
        if (found) {
          return found;
        }
      } else if (o.value === value) {
        return o as QSelectItem<TLabel, TValue>;
      }
    }
  }, [value, options]);

  const { QSelectPlaceholder: placeholder } = usePartitionedChildren(
    children,
    'QSelectPlaceholder',
  );

  return (
    <Select
      {...(baseProps as SelectProps<
        QSelectItem<TLabel, TValue>,
        false,
        QSelectGroup<string, TLabel, TValue>
      >)}
      {...props}
      menuPortalTarget={document.body}
      value={clearInputOnSelect ? null : selectedValue}
      isMulti={false}
      onChange={onChangeHandler}
      placeholder={placeholder.length ? placeholder : undefined}
      menuPlacement="auto"
    />
  );
};

export const QMultiSelect = <
  TLabel extends React.ReactChild = string,
  TValue = string,
>(
  props: QMultiSelectProps<TLabel, TValue>,
): React.ReactElement => {
  const { onChange, options, value, children } = props;

  type ItemType = QSelectItem<TLabel, TValue>;
  const onChangeHandler = (
    selected: MultiValue<ItemType> | SingleValue<ItemType>,
  ) => {
    const deNulled = selected ?? [];
    const selectedValues: ItemType[] = Array.isArray(deNulled)
      ? deNulled
      : [deNulled];
    onChange && onChange(selectedValues);
  };

  const selectedValue = useMemo(() => {
    if (!value) {
      return [];
    }
    return options.reduce((acc, o) => {
      if (isGroup(o)) {
        const found = o.options.filter((subOption) =>
          value.includes(subOption.value),
        );
        if (found) {
          acc.push(...found);
        }
      } else if (o.value !== undefined && value.includes(o.value)) {
        acc.push(o as QSelectItem<TLabel, TValue>);
      }
      return acc;
    }, [] as QSelectItem<TLabel, TValue>[]);
  }, [value, options]);

  const { QSelectPlaceholder: placeholder } = usePartitionedChildren(
    children,
    'QSelectPlaceholder',
  );

  return (
    <Select
      {...(baseProps as SelectProps<
        QSelectItem<TLabel, TValue>,
        true,
        QSelectGroup<string, TLabel, TValue>
      >)}
      {...props}
      menuPortalTarget={document.body}
      value={selectedValue}
      isMulti={true}
      onChange={onChangeHandler}
      menuPlacement="auto"
      placeholder={placeholder.length ? placeholder : undefined}
    />
  );
};
