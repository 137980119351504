import { useCallback, useMemo } from 'react';
import { useCumulativeSearchParams } from '../../../hooks/cumulativeSearchParams';
import { SetSortFn, SortingContextValue } from './context';

export type UseSortingSearchParamsResult = SortingContextValue;

/**
 * Handles sorting state variables as search params.
 * Uses a single search param to store both the column and the direction.
 * The direction is stored as a '-' prefix for descending.
 */
export const useSortingSearchParams = (
  sortByKey: string,
  initialSort?: {
    column: string;
    descending?: boolean;
  },
): UseSortingSearchParamsResult => {
  const [searchParams, setSearchParams] = useCumulativeSearchParams();

  const setSort: SetSortFn = useCallback(
    (column, descending) => {
      setSearchParams({ [sortByKey]: marshal(column, descending) });
    },
    [sortByKey, setSearchParams],
  );

  const rawSortBy = searchParams.get(sortByKey);

  // Memoising the context value to make it easier to avoid
  // unnecessary re-renders in consumers.
  return useMemo(() => {
    if (rawSortBy == null) {
      return {
        column: initialSort?.column ?? null,
        descending: !!initialSort?.descending,
        setSort,
      };
    }
    return { ...unmarshal(rawSortBy), setSort };
  }, [rawSortBy, setSort, initialSort]);
};

const marshal = (column: string, descending: boolean): string =>
  `${descending ? '-' : ''}${encodeURIComponent(column)}`;

const unmarshal = (
  sortBy: string,
): Pick<UseSortingSearchParamsResult, 'column' | 'descending'> => {
  const match = /^(-?)(.+)$/.exec(sortBy);
  if (match == null) {
    return { column: null, descending: false };
  }
  return { column: decodeURIComponent(match[2]), descending: match[1] === '-' };
};
