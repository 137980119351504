import React, { useCallback, useEffect, useState } from 'react';
import { V2NonUserResult } from '../Table/DataProviders';

export type QriProperties = Pick<
  V2NonUserResult,
  'domain' | 'resource' | 'identifiers' | 'urls' | 'resourceSubTypeName'
>;

/**
 * All the attributes that are required to generate a QRI string.
 */
export type QriAttributes = {
  domain: string;
  domainType: string;
  companyId: string;
  id: string;
  versionId?: string;
};

export type QRelatedRecordsDrawerProps = {
  /**
   * Optional properties about the referenced resource, including domain, resource type,
   * identifiers, URLs, and resource sub-type name. This is used to provide additional
   * context about the referenced item.
   */
  qriProperties?: QriProperties;

  /**
   * Either the Qualio Resource Identifier (QRI) as a string (base64 encoded), or all attributes that make up
   * the QRI before it gets encoded.
   * A QRI is a base64 encoded path to a qualio resource. For example document 123 from company 507 (documents/document/507/123) will be encoded to qri ZG9jdW1lbnRzL2RvY3VtZW50LzUwNy8xMjM=
   * This document can also be provided as an object, containing the following properties:
   *
   * companyId: 507;
   * id 123;
   * domain: 'documents';
   * domainType 'document';
   */
  qri?: string | QriAttributes;

  /**
   * The display label/title to show in the reference drawer header.
   * This provides a human-readable description of the referenced resource.
   */
  label: string;

  /**
   * Boolean flag indicating if the drawer is currently loading resource details.
   * When true, expect the drawer to show a loading state.
   */
  isLoading: boolean;

  /**
   * Optional array of additional QRI strings to fetch and display related resources.
   * These are supplementary resources that are related to the main referenced resource.
   * These should be in format that contains the qri service url. For example:
   * https://qri.app.staging.beta.qualio.com/ZG9jdW1lbnRzL2RvY3VtZW50LzUwNy8yNDE5YTZjYS01OTdlLTRkZjQtYjA0My1jNjdjNTcxY2Q2OTQ
   */
  additionalQris?: readonly string[];

  /**
   * Flag used to decide whether to display the 'View details' link in the reference drawer.
   */
  displayViewDetailsLink?: boolean;
};

const RELATED_RECORDS_DRAWER_EVENT_KEY_UPDATE = 'QReferenceDrawer-update';
const RELATED_RECORDS_DRAWER_EVENT_KEY_CLOSE = 'QReferenceDrawer-closed';

type RelatedRecordsDrawerUpdateEvent = CustomEvent<QRelatedRecordsDrawerProps>;

declare global {
  interface WindowEventMap {
    [RELATED_RECORDS_DRAWER_EVENT_KEY_UPDATE]: RelatedRecordsDrawerUpdateEvent;
    [RELATED_RECORDS_DRAWER_EVENT_KEY_CLOSE]: CustomEvent;
  }
}

export type UseRelatedRecordsDrawerEventListenerResult =
  QRelatedRecordsDrawerProps & {
    isOpen: boolean;
    onClose: () => void;
  };

const EMPTY_DRAWER_PROPS = {
  qri: '',
  label: '',
  isLoading: false,
  displayViewDetailsLink: true,
} as const satisfies QRelatedRecordsDrawerProps;

export const useRelatedRecordsDrawerEventListener =
  (): UseRelatedRecordsDrawerEventListenerResult => {
    const [props, setProps] =
      useState<QRelatedRecordsDrawerProps>(EMPTY_DRAWER_PROPS);

    useEffect(() => {
      const invokeCallback = (e: RelatedRecordsDrawerUpdateEvent) => {
        setProps(e.detail);
      };
      window.addEventListener(
        RELATED_RECORDS_DRAWER_EVENT_KEY_UPDATE,
        invokeCallback,
      );
      return () =>
        window.removeEventListener(
          RELATED_RECORDS_DRAWER_EVENT_KEY_UPDATE,
          invokeCallback,
        );
    }, [setProps]);

    const onClose = useCallback(() => {
      setProps(EMPTY_DRAWER_PROPS);
      closeDrawer();
    }, [setProps]);

    return {
      ...props,
      isOpen: !!props.qri,
      onClose,
    };
  };

/**
 * If `props` is defined and the `qri` field is truthy, then the reference drawer will be opened.
 * If `props` is null or the `qri` field is falsy, then the reference drawer will be closed.
 *
 * @param props The properties to display in the reference drawer.
 * @param onRemoteClose A callback to be invoked when the reference drawer is closed by another component.
 */
export const useRelatedRecordsDrawer = (
  props: QRelatedRecordsDrawerProps | null,
  onRemoteClose?: () => void,
): void => {
  // Notify the frontend to update the drawer whenever the props change.
  useEffect(() => {
    console.log(
      'Requesting that host frontend update the reference drawer',
      props,
    );

    const detail = props
      ? {
          ...props,
          displayViewDetailsLink: props.displayViewDetailsLink ?? true,
        }
      : EMPTY_DRAWER_PROPS;

    window.dispatchEvent(
      new CustomEvent(RELATED_RECORDS_DRAWER_EVENT_KEY_UPDATE, {
        detail,
      }),
    );
  }, [JSON.stringify(props)]);

  // Subscribe to notifications from elsewhere that the drawer has been closed.
  useEffect(() => {
    if (!onRemoteClose) {
      return;
    }
    const cb = onRemoteClose;
    window.addEventListener(RELATED_RECORDS_DRAWER_EVENT_KEY_CLOSE, cb);
    return () =>
      window.removeEventListener(RELATED_RECORDS_DRAWER_EVENT_KEY_CLOSE, cb);
  }, [onRemoteClose]);
};

/**
 * Exists solely for backwards compatibility with the old reference drawer.
 * @deprecated Use {@link useRelatedRecordsDrawer} instead.
 */
export const QReferenceDrawer: React.FC<QRelatedRecordsDrawerProps> = (
  props,
) => {
  useRelatedRecordsDrawer(props);
  return null;
};

const closeDrawer = () => {
  window.dispatchEvent(new CustomEvent(RELATED_RECORDS_DRAWER_EVENT_KEY_CLOSE));
};
