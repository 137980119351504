import { QIcon } from '../../QIcons';
import { QLink, QLinkProps } from '../../QAtoms/Link/Link';
import React from 'react';
import styled from '@emotion/styled';

export type QBackLinkProps = QLinkProps;

const BackLink = styled(QLink)`
  svg {
    vertical-align: middle;
    margin-right: 8px;
  }
`;

export const QBackLink: React.FC<QBackLinkProps> = ({
  children,
  ...linkProps
}) => {
  return (
    <BackLink {...linkProps}>
      <QIcon iconName="ArrowLeft" />
      {children}
    </BackLink>
  );
};

QBackLink.displayName = 'QBackLink';
