import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DataProps } from '../../utils';
import { QCheckbox } from '../../QAtoms';
import {
  QDataColumn,
  QDataRow,
  QDataTable,
  QDefaultSortingColumn,
} from '../../QAtoms/Table';
import { DataView, toColumns } from './View';
import { CellRenderProps } from './CellRenderProps';
import { SelectableDataAccessors, Action } from './types';
import { QSpinner } from '../../QAtoms/Spinner/Spinner';
import { QModalFullBody } from '../../QComponents/Modal/ModalFullBody';
import { QEmptyState } from '../../QComponents/EmptyState/EmptyState';
import { resolveDisplayConditions } from './SelectHelpers';
import { QBox } from '../../QLayouts';

/**
 * In these props, the generic type `V` is the data required to be displayed in the list.
 * The generic type `T` is the type of the data that is selected, and is a superset of `V`.
 */
export type QSelectableListProps<V, T extends V = V> = {
  data: readonly T[];

  /** Callback invoked when the user changes a selection.
   *  Only the changed items are passed in the callback.
   */
  onDataChange: (changes: readonly T[]) => void;

  /** Keys for the id and the selected state.
   *  The `id` key is used to uniquely identify each item.
   *  The `selected` key is used to determine if an item is selected.
   *  The `disabled` key is used to determine if an item is disabled.
   */
  accessors: SelectableDataAccessors<T>;

  /** The view to be displayed in the list.
   * Defines the columns to be shown.
   */
  view: DataView<V>;

  defaultSortBy?: QDefaultSortingColumn;

  /**
   * Hides checkboxes if true.
   */
  isReadOnly?: boolean;
  /**
   * Flag to determine if table should pin selected rows to top of table
   */
  shouldPin?: boolean;
  /**
   * Indicates if the data is currently being loaded from API.
   */
  isDataLoading: boolean;

  /**
   * Current search term that has been set by the user.
   */
  currentSearchTerm: string;
} & DataProps;

export function QSelectableList<V, T extends V = V>({
  data,
  onDataChange,
  accessors,
  view,
  defaultSortBy,
  isReadOnly,
  shouldPin = false,
  isDataLoading,
  currentSearchTerm,
  ...dataProps
}: QSelectableListProps<V, T>): React.ReactElement {
  const isSelected = useCallback(
    (t: T): boolean => !!t[accessors.selected],
    [accessors.selected],
  );

  const [lastAction, setLastAction] = useState<Action>('typing');
  useEffect(() => setLastAction('typing'), [currentSearchTerm]);

  const onChange = useCallback(
    (changed: T) => {
      setLastAction('selecting');
      onDataChange([changed]);
    },
    [onDataChange],
  );

  const onSelectAllChanged = useCallback(
    (selectAll: boolean) => {
      setLastAction('selecting');
      onDataChange(
        data
          .filter(
            (datum) =>
              !datum[accessors.disabled] &&
              datum[accessors.selected] !== selectAll,
          )
          .map((datum) => ({ ...datum, [accessors.selected]: selectAll })),
      );
    },
    [data, onDataChange],
  );

  const checkboxHeader = useMemo(() => {
    const enabledData = data.filter((datum) => !datum[accessors.disabled]);

    const numSelected = enabledData.filter(isSelected).length;

    const isIndeterminate = numSelected > 0 && numSelected < enabledData.length;

    return (
      <QCheckbox
        isIndeterminate={isIndeterminate}
        onChange={(e) => onSelectAllChanged(e.target.checked)}
        isChecked={enabledData.some(isSelected)}
        data-cy="selectable-list-master-checkbox"
      />
    );
  }, [data, onSelectAllChanged]);

  const checkboxCell = useCallback(
    ({ row: { original } }: CellRenderProps<T>) => (
      <ItemCheckbox item={original} onChange={onChange} accessors={accessors} />
    ),
    [onChange, accessors],
  );

  const columns = useMemo(
    (): QDataColumn[] =>
      isReadOnly
        ? toColumns(view)
        : [
            {
              id: 'selected',
              disableSortBy: true,
              width: 0,
              accessor: accessors.selected,
              Header: checkboxHeader,
              Cell: checkboxCell,
            },
            ...toColumns(view),
          ],
    [checkboxHeader, checkboxCell, data, onChange, isReadOnly],
  );

  const {
    shouldDisplayTable,
    shouldDisplaySpinner,
    shouldDisplayBlankState,
    shouldDisplayNoResultState,
  } = useMemo(
    () =>
      resolveDisplayConditions(
        data.length,
        data.filter(isSelected).length,
        isDataLoading,
        currentSearchTerm,
        lastAction,
      ),
    [data, isDataLoading, currentSearchTerm, lastAction],
  );

  return (
    <div {...dataProps}>
      <QBox hidden={!shouldDisplayTable}>
        <QDataTable
          columns={columns}
          data={data as unknown as QDataRow[]} // FIXME: This type of assertion is to be avoided.
          hasPagination={false}
          shouldPin={shouldPin}
          manualSortBy={
            defaultSortBy ? { defaultSortByColumn: [defaultSortBy] } : undefined
          }
        />
      </QBox>

      {shouldDisplaySpinner && (
        <QModalFullBody>
          <QSpinner />
        </QModalFullBody>
      )}

      {shouldDisplayBlankState && (
        <QModalFullBody>
          <QEmptyState title="Type to start searching..." />
        </QModalFullBody>
      )}

      {shouldDisplayNoResultState && (
        <QModalFullBody>
          <QEmptyState
            title="No results found"
            subtitle="We couldn't find any matches, please try again."
          />
        </QModalFullBody>
      )}
    </div>
  );
}

type ItemCheckboxProps<T> = {
  item: Readonly<T>;
  onChange: (item: T) => void;
  accessors: SelectableDataAccessors<T>;
};

const ItemCheckbox = <T,>({
  item,
  onChange,
  accessors,
}: ItemCheckboxProps<T>): React.ReactElement => (
  <QCheckbox
    onChange={(e) =>
      onChange({ ...item, [accessors.selected]: e.target.checked })
    }
    isChecked={!!item[accessors.selected]}
    isDisabled={!!item[accessors.disabled]}
    data-cy={`selectable-list-checkbox-${item[accessors.id]}`}
  />
);
